import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { format, isBefore, isToday } from "date-fns";
import { useTranslation } from "react-i18next";
import { API_ENDPOINTS } from "../api/apiConfig"; // Adjust the path to your API config

const OrderList = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await fetch(API_ENDPOINTS.getdataorderanduser, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ KEY: process.env.REACT_APP_API_KEY }),
        });

        if (!response.ok) {
          throw new Error(t("errorFetchingOrders"));
        }

        const data = await response.json();
        console.log("dataaaaaaaaaaaaaaaaaa", data);
        setOrders(data); // Assuming data is an array of objects with { id, date, MyOrders }

        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchOrders();
  }, [t]);

  const getBackgroundColor = (order) => {
    return !order.done ? "red" : "green";
  };

  const handleCheckboxChange = async (orderId, currentDoneStatus) => {
    // Ensure currentDoneStatus is treated as a boolean
    const newDoneStatus = !(currentDoneStatus ?? false);

    setLoading(true);
    console.log(
      "Updating order status:",
      orderId,
      currentDoneStatus,
      `${API_ENDPOINTS.BillNumberUp}/${orderId}`
    );

    try {
      const response = await fetch(`${API_ENDPOINTS.BillNumberUp}/${orderId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          done: newDoneStatus,
          KEY: process.env.REACT_APP_API_KEY,
        }),
      });

      if (!response.ok) {
        const errorText = await response.text(); // Log the error response
        console.error(`Error ${response.status}:`, errorText);
        throw new Error(t("errorUpdatingStatus"));
      }

      const updatedOrder = await response.json();
      console.log("no errorrrrrrrrrrrrrrr   ", updatedOrder);
      // Update the state locally
      setOrders((prevOrders) =>
        prevOrders.map((order) =>
          order.id === orderId
            ? { ...order, done: newDoneStatus } // Update the "done" status
            : order
        )
      );
      setLoading(false);
    } catch (error) {
      console.error("Error updating order status:", error.message);
      alert(t("errorUpdatingStatus")); // Optional: Add a user-friendly error message
      setLoading(false);
    }
  };

  // Navigate to the order details page with the user and order details
  const handleCardClick = (order, nameUser) => {
    //console.log(`/users/${order.MyOrders[0].User.iduser}/orders/${order.id}`)

    navigate(`/users/${order.MyOrders[0].User.iduser}/orders/${order.id}`, {
      state: {
        order: order, // Pass the order object
        nameUser: nameUser, // Pass the user name for display in OrderDetails
      },
    });
  };

  if (loading) return <div>{t("loading")}</div>;
  if (error)
    return (
      <div>
        {t("error")}: {error}
      </div>
    );

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        {t("orderListTitle")}
      </Typography>
      <Grid container spacing={3}>
        {orders.map((order) => {
          console.log("sdfsdfsdfsd    ", order["MyOrders"]);
          if (order["MyOrders"].length === 0) {
            console.log("No orders found");
            return null;
          } else {
            const user =
              order.MyOrders.length > 0 ? order.MyOrders[0].User : null;
            const userName = user ? user.name : "No User Name";

            return (
              <Grid item xs={12} md={4} key={order.id}>
                <Card
                  style={{
                    backgroundColor: getBackgroundColor(order),
                    color: "#fff",
                    cursor: "pointer",
                  }}
                  onClick={() => handleCardClick(order, userName)} // Navigate on card click
                >
                  <CardContent>
                    <Typography variant="h6">
                      {t("orderId")}: {order.id}
                    </Typography>
                    <Typography>
                      {t("orderDate")}:{" "}
                      {format(new Date(order.date), "dd/MM/yyyy")}
                    </Typography>
                    <Typography>
                      {t("orderTime")}: {format(new Date(order.date), "HH:mm")}
                    </Typography>
                    <Typography>{userName}</Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={order.done}
                          onClick={(event) => event.stopPropagation()}
                          onChange={() =>
                            handleCheckboxChange(order.id, order.done ?? false)
                          }
                        />
                      }
                      label={
                        order.done ? t("markAsComplete") : t("markAsIncomplete")
                      }
                    />
                  </CardContent>
                </Card>
              </Grid>
            );
          }
        })}
      </Grid>
    </Container>
  );
};

export default OrderList;
