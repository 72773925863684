const API_BASE_URL = process.env.REACT_APP_API_URL;

export const API_ENDPOINTS = {
  imageChange: `${API_BASE_URL}/uploadProduct`,
  editProduct: `${API_BASE_URL}/products`,
  getTypes: `${API_BASE_URL}/types`,
  getSaleFor: `${API_BASE_URL}/SaleFor`,
  createProduct: `${API_BASE_URL}/products/create`,
  createTypes: `${API_BASE_URL}/types/create`,
  getAllUser: `${API_BASE_URL}/users/type/user`,
  getProducts: `${API_BASE_URL}/products/getProducts`,
  getProductsNoActiv: `${API_BASE_URL}/products/getProductsNoActiv`,

  ordersOneUser: `${API_BASE_URL}/orders/user/orders`,
  orders: `${API_BASE_URL}/orders/order`,
  AddUser: `${API_BASE_URL}/users`,
  uploadImageUser: `${API_BASE_URL}/upload`,
  saleFor: `${API_BASE_URL}/saleFor`,
  saveOrder: `${API_BASE_URL}/orders/saveOrder`,
  createSaleFor: `${API_BASE_URL}/saleFor/create`,
  getUsersPyEmail: `${API_BASE_URL}/users/email`,
  deleteType: `${API_BASE_URL}/products/delete`,
  productTypes: `${API_BASE_URL}/products/productTypes`,
  updateSaleFor: `${API_BASE_URL}/products/updatesaleFor`,
  updateOrder: `${API_BASE_URL}/orders/`,
  BillNumberUp: `${API_BASE_URL}/orders/BillNumberUp`,
  Login: `${API_BASE_URL}/users/email/`,
  EditDataUser: `${API_BASE_URL}/users/update/`,
  deleteOrder: `${API_BASE_URL}/orders/`,
  updateOrderCount: `${API_BASE_URL}/orders/count/`,

  addOrderItem: `${API_BASE_URL}/orders/createOrderInAdmin`,
  getdataorderanduser: `${API_BASE_URL}/orders/getdataorderanduser`,
  usersUpdateId: `${API_BASE_URL}/users/updateApp`,
  updateOrderPrice: `${API_BASE_URL}/orders/price/`,
};
